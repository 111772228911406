<template>
</template>

<script>
/* global gon */

import { translate, serializeForm, showException, showTicketsNotConfirmedAlert } from "app/helpers";
import { Track } from "app/helpers/track";

function tryGetItineraryTemplate(itinerary) {
  if (itinerary &&
      itinerary.attributes.template &&
      itinerary.attributes.template.id) {
    return itinerary.attributes.template.id;
  }
  return null;
}

export default {
  props: {
    itinerary: {
      type: Object,
      required: true
    }
  },
  data() {
    const bags = this.itinerary.attributes.bags;
    return {
      selectedPrice: null,
      selectedPricePax: null,
      selectedPriceBags: null,
      selected: false,
      selectedBags: bags,
      climateSelected: true,
      cfarSelected: true,
      hasBagsValue: false,
      allBagsSelected: false,
      allCabinBagsSelected: false,
      allCheckedBagsSelected: false,
      allFakeBagsSelected: false
    };
  },
  computed: {
    hasBags() {
      return this.hasBagsValue;
    },
    currentPriceBags() {
      return this.selectedPriceBags;
    },
    currentPrice() {
      if (this.itinerary.attributes.template.id == 5) {
        return this.$options.filters.currency(this.selectedPrice || this.itinerary.attributes.template.std_price_raw);
      } else {
        return this.$options.filters.currency(this.selectedPrice || this.itinerary.attributes.price_raw);
      }
    },
    currentPricePax() {
      return this.$options.filters.currency(this.selectedPricePax || this.itinerary.attributes.price_pax_raw);
    },
    locked() {
      if (this.itinerary.attributes.banned) {
        return true;
      }
      if (this.selected) {
        return false;
      }
      return this.$store.state.lockItineraries;
    },
    showSubmit() {
      if (gon.hide_submit_button) {
        return false;
      } else {
        return true;
      }
    },
    submitAltText() {
      const climateElem = $(`[data-uuid="${this.itinerary.id}"] [name="climate"]`);
      if (climateElem && this.climateSelected && this.itinerary.attributes.template.alt_text_climate) {
          return this.itinerary.attributes.template.alt_text_climate;
      }

      if (this.itinerary.attributes.template.large_diff) {
        return translate("Sök flyg med bagage")
      }

      return this.itinerary.attributes.template.alt_text;
    },
    submitText() {
      if (this.$store.state.alternativeViewState !== null) {
        const priceText = (this.currentPricePax > 0) ? `+${this.currentPricePax}` : this.currentPricePax;
        return `${translate("Byt flyg:")} ${priceText} ${translate("per person")}`;
      }
      var price = this.currentPrice;

      if ((this.itinerary.attributes.template.alt_uuid && !this.allFakeBagsSelected) ||
          !this.itinerary.attributes.bags_included && !this.hasBagsValue && !this.allBagsSelected && !this.allCheckedBagsSelected && !this.allCabinBagsSelected && !this.allFakeBagsSelected) {
        if ((this.carrier_without_hand_baggage(this.itinerary) == true) || this.itinerary.attributes.itinerary.some(x => (x.only_underseat != null) && (x.only_underseat != false)) ) {
          return `${translate("Flyg:")} ${price}`;
        } else {
          return `${translate("Välj flyg utan bagage:")} ${price}`;
        }
      }

      if (this.itinerary.attributes.bags_included || this.hasBags || this.allCabinBagsSelected || this.allCheckedBagsSelected || this.allFakeBagsSelected ) {
        if (this.allBagsSelected) {
          return `${translate("Välj flyg + fullt bagage:")} ${price}`;
        } else if (this.allCabinBagsSelected) {
          return `${translate("Välj flyg + kabinväskor:")} ${price}`;
        } else if (this.allCheckedBagsSelected) {
          return `${translate("Välj flyg och bagage:")} ${price}`;
        } else if (this.allFakeBagsSelected) {
          return `${translate("Välj flyg och bagage:")} ${price}`;
        } else {
          return `${translate("Välj flyg och bagage:")} ${price}`;
        }
      } else {
        return `${translate("Välj flyg:")} ${price}`;
      }
    },
    climateTooltipText() {
        return translate(
            "<strong>Så har vi räknat</strong><br />Den här flighten släpper ut %{amount} kg CO<sub>2</sub>.<br /><br />" +
            "Uträkningen av CO<sub>2</sub>-mängden är baserad på en algoritm framtagen vid en studie vid Kungliga Tekniska Högskolan, " +
            "KTH i Stockholm. Algoritmen tar hänsyn bland annat till verklig flygsträcka, respektive flygbolagen beläggningsgrad, " +
            "antalet mellanlandningar, flygplanstyp, flygplanens ålder, om flygplanet har winglets med mera.<br /><br />" +
            "<strong>Klimatkompensation</strong><br />Vår partner för klimatkompensation är Go Climate Neutral.",
            this.itinerary.attributes.climate.emission);
    },
    month_price_url() {
        const raw_price = this.selectedPrice || this.itinerary.attributes.price_raw;
        return `/payment/annuity_description/${raw_price * 100}`;
    }
  },
  methods: {
    opCarrierText(operating_carrier) {
      return operating_carrier ? operating_carrier : "&nbsp";
    },
    includedCarryOn(included_carryon_baggage) {
      if (included_carryon_baggage["CABIN"]["count"] > 0) {
        return translate("Handbagage ingår");
      } else {
        return translate("Handbagage ingår ej");
      }
    },
    searchMeta() {
      return gon.meta_search;
    },
    generatePriceCalculationPath(uuid) {
      return `/price_calculation/${gon.search.id}/${uuid}`;
    },
    cfarTooltipText(cost) {
        return translate(
          "Fri avbokning %{cost}", cost)
    },
    generateTicketrulesPath() {
      if (this.itinerary.attributes.template.alt_uuid) {
        return `/rules/${gon.search.id}/${this.itinerary.attributes.uuid}/${this.itinerary.attributes.template.alt_uuid}`;
      } else {
        return `/rules/${gon.search.id}/${this.itinerary.attributes.uuid}`;
      }
    },
    generateFlightnumberTooltip(segment) {
      return `${segment.flight_text}<br/>${segment.equip_text}<br/><br/>${segment.brand_name}`;
    },
    generateBaggageTooltip(segment) {
      var bag = segment.baggage;

      if (bag.type === "included") {
        return `${translate("Bagage per person:")}<br/>${bag.text}`;
      } else if (bag.type === "extra") {
        return bag.text
      } else if ((segment.only_underseat == true) || this.segment_without_hand_baggage(segment)) {
        return translate('Varken incheckat bagage eller handbagage ingår.');
      } else {
        if (segment.included_carryon_baggage !== null && ((segment.included_carryon_baggage["BAG_COMBINED"] !== null) || (segment.included_carryon_baggage !== null && segment.included_carryon_baggage["CABIN"] !== null && segment.included_carryon_baggage["CABIN"]["count"] > 0))) {
          return `${translate("Handbagage ingår.")}<br>${translate("Incheckat bagage:")} ${bag.text}`;
        } else {
          return `${translate("Incheckat bagage:")} ${bag.text}`;
        }
      }
    },
    allotmentDatesText() {
      return translate("allotment.similar_dates");
    },
    allFlights(itinerary) {
      let segments = [];
      itinerary.itinerary.forEach((itin) => {
        itin.segments.forEach((segment) => {
          segments.push(segment);
        });
      });
      return segments;
    },
    hasCabins() {
      const cabinCodes = this.allCabinsCodes();
      return !(cabinCodes.length === 1 && cabinCodes[0] === "EC");
    },
    allCabinsCodes() {
        let cabins = {};
        this.itinerary.attributes.itinerary.forEach((itin) => {
            itin.segments.forEach((segment) => {
                cabins[segment.cabin_code] = true;
            });
        });
        return Object.keys(cabins);
    },
    allCabins() {
      let cabins = {};
      this.itinerary.attributes.itinerary.forEach((itin) => {
        itin.segments.forEach((segment) => {
          cabins[segment.cabin] = true;
        });
      });
      return Object.keys(cabins);
    },
    allCabinsText() {
      return this.allCabins().join(` ${translate("och")} `);
    },
    placesLeftText(num) {
      return (num === 1) ? translate("Bara en plats kvar!") :
                           translate("Bara %{num} platser kvar!", num);
    },
    bagsChange() {
      this.updateSelectedBags();
      this.priceChange();
    },
    searchWithBags() {
      event.preventDefault();
      $('#search_only_with_bags').prop('checked', true);
      $('.main_btn_wide').click();
    },
    updateSelectedBags() {
      let selected = false;
      for (const pax in this.selectedBags) {
        if (this.selectedBags.hasOwnProperty(pax)) {
          const paxVal = this.selectedBags[pax];
          for (const bag in paxVal.items) {
            if (paxVal.items.hasOwnProperty(bag) && paxVal.items[bag].selected) {
              selected = true;
              break;
            }
          }
        }
      }
      this.hasBagsValue = selected;

      this.allCabinBagsSelected = $(`[data-uuid="${this.itinerary.id}"] [type="checkbox"][data-ancillary-type="CABIN"]`).length > 0 &&
                             !$(`[data-uuid="${this.itinerary.id}"] [type="checkbox"][data-ancillary-type="CABIN"]`).is(":not(:checked)");

      this.allCheckedBagsSelected = $(`[data-uuid="${this.itinerary.id}"] [type="checkbox"][data-ancillary-type="BAG"]`).length > 0 &&
                            !$(`[data-uuid="${this.itinerary.id}"] [type="checkbox"][data-ancillary-type="BAG"]`).is(":not(:checked)");

      this.allFakeBagsSelected = $(`[data-uuid="${this.itinerary.id}"] [type="checkbox"][data-ancillary-type="fake"]`).length > 0 &&
                            !$(`[data-uuid="${this.itinerary.id}"] [type="checkbox"][data-ancillary-type="fake"]`).is(":not(:checked)");

      this.allBagsSelected = (this.allCheckedBagsSelected && this.allCabinBagsSelected)
    },
    priceChange() {
      let newPricePax = this.itinerary.attributes.price_pax_raw;
      let newPriceBags = 0;

      $(`[data-uuid="${this.itinerary.id}"] [type="checkbox"]`).each((index, elem) => {
        if (elem.checked) {
          newPriceBags += Number(elem.getAttribute("data-price"));
          newPricePax += Number(elem.getAttribute("data-price-pax"));
        }
      });

      if (this.itinerary.attributes.template.id == 5) {
        this.selectedPrice = this.itinerary.attributes.template.std_price_raw + newPriceBags
      } else {
        this.selectedPrice = this.itinerary.attributes.price_raw + newPriceBags;
      }
      this.selectedPricePax = newPricePax;
      this.selectedPriceBags = newPriceBags;
      return true;
    },
    getElementQuery() {
      return `[data-uuid="${this.itinerary.id}"]`;
    },
    setUUID(uuid) {
      $(`${this.getElementQuery()} input[name="uuid"]`).val(uuid);
    },
    extractFormBags() {
      const target = $(`[data-uuid="${this.itinerary.id}"]`);
      const submitData = serializeForm(target);
      return submitData.baggage;
    },
    submit(event) {
      event.preventDefault();

      const target = $(event.target);
      const submitData = serializeForm(target);

      // Add loading to all itineraries
      this.selected = true;
      this.$store.state.lockItineraries = true;

      Track.log(`Click on itinerary (template: ${tryGetItineraryTemplate(this.itinerary)})`, this.itinerary);

      let self = this;
      window.addEventListener( "pageshow", ( event ) => {
        if (event.persisted || (typeof window.performance != "undefined" && window.performance.navigation.type === 2)) {
          self.$store.state.lockItineraries = false;
          self.selected = false;
        }
      });

      this.$http.post(`/search/check/${gon.search.id}`, submitData)
                .then((response) => {
                    if (response.body.available) {
                      window.location.href = response.body.url;
                    } else {
                      showTicketsNotConfirmedAlert();
                    }
                  }, (response) => {
                    showException();
                  });
    },
    carrier_without_hand_baggage(itinerary) {
      let result = false;
      itinerary.attributes.carriers.forEach((carrier) => {
        if (['DY', 'D8'].includes(carrier.code)) {
          result = true;
        }
      });
      return result;
    },

    segment_without_hand_baggage(segment) {
      let result = false;
      if (['DY', 'D8'].includes(segment.op_carrier_code)) {
        result = true;
      }
      return result;
    }
  },
  mounted() {
    const tooltipElements = $("[tooltip]:not(.tooltipstered)", this.$refs.form);
    tooltipElements.each((index) => {
        const elem = $(tooltipElements[index]);
        const maxWidth = elem.attr("tooltip-max-width");
        elem.tooltipster({
            contentAsHTML: true,
            maxWidth
        });
    });

    $("[fancybox]", this.$refs.form).fancybox();
    $("[fancybox]", this.$refs.form).on("click", function (eventObject) {
       eventObject.preventDefault();
    });

    this.$nextTick(() => {
      this.priceChange();
      this.updateSelectedBags();
    });
  }
};
</script>
