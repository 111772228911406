/* global gon */

import "app/helpers/compatibility";

import "../../components/desktop/setup";

import "air-datepicker/dist/js/datepicker.min.js";

import "../../components/desktop/searchfield/languages/datepicker.se";
import "air-datepicker/dist/css/datepicker.min.css";

import Vue from "vue";
import "app/setup";
import store from "app/store/store";

import Searchfield from "components/desktop/searchfield";

import Step1Body from "app/desktop/step_1_body";

//import Map from "@aobtravel/falco-webkit/src/components/molecules/map/map";
import { newComponent } from "@aobtravel/falco-webkit/src/support";

import DebugPanel from "app/desktop/debug/debug_panel";

function addDebugPanel() {
  if (gon.debug_enabled == null) {
    return;
  }

  let debugElem = document.createElement("div");
  $("body")[0].appendChild(debugElem);

  new Vue({
    el: debugElem,
    store: store,
    render: (h) => h(DebugPanel)
  });
}



$(document).ready(() => {
  Searchfield.load();

  if (gon.search) {
    store.dispatch("createSearchChannel");
  }

  new Vue({
    el: document.getElementById("step_1_body"),
    store: store,
    mounted: () => {
      store.dispatch("updateGon");
    },
    render: (h) => h(Step1Body)
  });

  if (gon.debug_enabled) {
    addDebugPanel();
  }

  // See more in _hotel_info.html file
  const body = document.getElementsByTagName("body")[0];
  body.addEventListener("hotel-loaded", () => {
    const elem = document.querySelector("#map-canvas:not([attached])");
    if (typeof elem !== "undefined") {
      const lat = elem.getAttribute("data-lat");
      const lng = elem.getAttribute("data-lng");

      newComponent(elem, Map, {lat, lng});
      elem.setAttribute("attached", "");
    }
  }, false);

  $(".youth-tooltip").tooltipster({
    maxWidth: 400,
    contentAsHTML: true
  });

  const timeoutAt = document.querySelector('#step1-timer').getAttribute('data-timeout-at');
  const timeLeft = timeoutAt - Date.now()

  setTimeout(() => {
    $.fancybox.open({
      src: "#step1-timer",
      opts: {
        afterClose : function() {
          $(".main_btn_wide").trigger(("click"))
        },
        backdropClick: "close",
        closeExisting: true,
        clickOutside: "close",
        clickSlide: "close",
        dragToClose: true,
        mobile: {
          clickOutside : true
        },
        smallBtn: true,
        touch: true
      }
    })
  }, timeLeft);

  $("#step-1-timer-button").on("click", function() {
    $(".main_btn_wide").trigger(("click"));
    $(".timeout-spinner").css("display", "block");
  });
});
